import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/core/services/crud/crud.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubcategoriesService extends CrudService{

  endpoint = 'subcategories'

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }
}
