import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends CrudService {

  endpoint = 'offers';
  url = environment.apiUrl;

  constructor( http: HttpClient, dialog: MatDialog) {
    super(http, dialog)
   }

  deleteSubcategory(offer_id: string, subcategory_id: string): Promise <any> {
    return this.http.delete(`${this.url}/${this.endpoint}/${offer_id}/subcategories/${subcategory_id}`).toPromise();
  }

  editSubcategoryBulk(offer_id: string, body, action): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${offer_id}/subcategories/bulk/${action}`, body).toPromise();
  }

  editCategoryBulk(offer_id: string, body, action): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${offer_id}/categories/bulk/${action}`, body).toPromise();
  }

  postNewSubcategory(offer_id: string, body): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${offer_id}/subcategories`, body).toPromise();
  }
  deleteCategory(offer_id: string, category_id: string): Promise <any> {
    return this.http.delete(`${this.url}/${this.endpoint}/${offer_id}/categories/${category_id}`).toPromise();
  }

  postNewCategory(offer_id: string, body): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${offer_id}/categories`, body).toPromise();
  }

  editProductBulk(offer_id: string, body, action): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${offer_id}/offer_products/bulk/${action}`, body).toPromise();
  }

  postNewProduct(offer_id: string, body): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${offer_id}/offer_products`, body).toPromise();
  }
  deleteProduct(offer_id: string, offer_product_id: string): Promise <any> {
    return this.http.delete(`${this.url}/${this.endpoint}/${offer_id}/offer_products/${offer_product_id}`).toPromise();
  }

  getAllSeller(seller_id: string, pagination: any): Promise <any> {
    let params = new HttpParams();
    if(seller_id){
      params = params.append('seller_id', seller_id);
    }

    if(pagination){
      params = params.append('page', (pagination.currentPage ).toString());
      params = params.append('limit', (pagination.itemsPerPage).toString());
    }

    return this.http.get(`${this.url}/${this.endpoint}/`, {params}).toPromise()
  }

}
