import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends CrudService {

  endpoint = 'customers'
  url = environment.apiUrl;

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }

  getInfos(pagination, rangeDate, search = ''): Promise<any>{
    let params = new HttpParams();

    if(pagination){
      params = params.append('limit', (pagination.itemsPerPage).toString());
      params = params.append('page', (pagination.currentPage ).toString());
    }

    if(rangeDate?.end && rangeDate?.start){
      params = params.append('range_to_created_at', rangeDate.end);
      params = params.append('range_from_created_at', rangeDate.start);
    }

    if(search){
      params = params.append('search', search);
    }


    return this.http.get(`${this.url}/${this.endpoint}`, {params} ).toPromise();
  }
}
