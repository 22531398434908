import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicInfoTypeService extends CrudService{


  endpoint = 'dynamic-info-type';
  url = environment.apiUrl;

  constructor( http: HttpClient, dialog: MatDialog) {
    super(http, dialog)
  }


  orderFields(id, body): Promise<any>{
    return this.http.put(`${this.url}/${this.endpoint}/${id}/fields`, body).toPromise()
  }

  createFields(id, body): Promise<any>{
    return this.http.post(`${this.url}/${this.endpoint}/${id}/fields`, body).toPromise()
  }

  updateFields(id, body): Promise<any>{
    return this.http.put(`${this.url}/${this.endpoint}/${id}/fields`, body).toPromise()
  }



  removeFields(id, body): Promise<any>{
    // let bodyString = JSON.stringify(body)
    // const teste = {
    //   method: 'DELETE',
    //   body: bodyString
    // }
    // return fetch(`${this.url}/${this.endpoint}/${id}/fields`, teste )
    let req = new HttpRequest('DELETE', `${this.url}/${this.endpoint}/${id}/fields`,  );
    req = req.clone({body: body});
    // return this.http.delete(`${this.url}/${this.endpoint}/${id}/fields`, body  ).toPromise();
    return this.http.request(req).toPromise();
  }

}
